import React,{useState, useEffect, useContext} from 'react';
import './Profile.css';
import profileGirl from '../images/myphotos/profilegirl.png';
import {FaGrinWink,FaVenusMars, FaRegBuilding, FaBirthdayCake, FaCircle,FaMoneyCheck} from 'react-icons/fa';
import {RiMoneyDollarCircleFill} from 'react-icons/ri';
import { AiOutlineSearch } from "react-icons/ai";
import {BsGlobe} from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import {AiFillHeart, AiFillLike, AiFillLock} from 'react-icons/ai';
import { useParams } from "react-router-dom";
import Account from "../Components/Profile/Profile";
import ImageModal from "../Components/ImageModal/ImageModal";
import CreditsModal from "../Components/CreditsModal/CreditsModal";
import Modal from "../Components/Modal/Modal";
import { UserContext } from "../App";
import config from "../config";
import axios from 'axios';
import loadingGif from "../images/myphotos/loading2.gif"
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import { toast } from "react-toastify";
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';

function ProfileUser() {
  // const token = localStorage.getItem('token') ? localStorage.getItem('token') : false;

    const {id} = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState();
    const [modalUnlockPhotoValue, setModalUnlockPhotoValue] = useState("");
    const [displayModalUnlockPhoto, setDisplayModalUnlockPhoto] = useState(false);
    const [displayModalPhoto, setDisplayModalPhoto] = useState();
    const [displayModalTips, setDisplayModalTips] = useState();
    const [modalPhotoSrc, setModalPhotoSrc] = useState();
    const [modalUnlockPhotoText, setModalUnlockPhotoText] = useState();
    const [modalUnlockPhotoCredits, setModalUnlockPhotoCredits] = useState();
    const [modalIdPhotoUnlock, setModalIdPhotoUnlock] = useState();
    const [userLiked, setUserLiked] = useState();
    const { t, i18n, ready } = useTranslation();
    const [pricingPlans,setPricingPlans] = useState();
   // Modal Gift
   const [giftModalId, setGiftModalId] = useState(false);
   const [displayModalGift, setDisplayModalGift] = useState();
   const [giftModalText, setGiftModalText] = useState();
   const [giftModalCredits, setGiftModalCredits] = useState();
   const [giftName, setGiftName] = useState();


   const [warningMessage,setWarningMessage] = useState(false);

    const [gifts,setGifts] = useState();
    const getProfileDetails = async () => {
      const response = await axios.get(
          config.url + "users/id/" + id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      setUser(response.data.user);
    };
    useEffect(() => {
        getProfileDetails();

        
        getGifts()
            .then((result) => {
                if (result.status == 200) {
                    setGifts(result.data.gifts);
                }
            })
            .catch((error) => {
                console.log(error);
                // if (error.response.status === 401) {
                //   setUser({ loggedIn: false });
                // }
            });

      }, [id]);
    

      
    function handleGiftClick(e){

      if(e.target.getAttribute("data-id") && e.target.getAttribute("data-title")){
        setDisplayModalGift(true);
        setGiftName(e.target.getAttribute("data-title"));
        setGiftModalId(e.target.getAttribute("data-id"));
        setGiftModalText(t("Are you sure you want to send the following gift: ") + e.target.getAttribute("data-title"));
        setGiftModalCredits(e.target.getAttribute('data-credits'));
      }
    }

      const setModalUnlock = async (e) => {
        e.preventDefault();
        const photoId = e.target.getAttribute("data-id");
        const photoCredits = e.target.getAttribute("data-credits");
        setDisplayModalUnlockPhoto(true);
        setModalUnlockPhotoText(t("Are you sure you want to unlock this photo?"));
        setModalUnlockPhotoValue(true);
        setModalUnlockPhotoCredits(photoCredits);
        setModalIdPhotoUnlock(photoId);
      };

      // Cadouri
      const getGifts = async () => {
        return await axios.get(config.url + "gifts/", {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
    };

    const setStateModalGift = async (modalValue) => {

      if(modalValue == false && giftModalId){
        setDisplayModalGift(false);
      }
      if(modalValue && giftModalId){
        axios
        .post(
          config.url + "order/gift/" + giftModalId,
          {user_id: id},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((result) => {
          if (result.status === 200) {
            toast(giftName + " " +  t("was send!")  );
          }
        })
        .catch((error) => {
          toast(error.response.data.message);
          setTimeout(() => {navigate('/membership-credits') },3500);
        });

        setDisplayModalGift(false);
      }
    }


      function likeProfile(e) {
        e.preventDefault();
        axios
          .post(
            config.url + "likes/add",
            {
              user_id: id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((result) => {
            if (result.status === 200) {
              toast("You liked "+user.username+"'s profile");
              setUserLiked(true);
            }
          })
          .catch((error) => {
  
            console.log(error.response);

           
          });
      }

      function handleImageClick(e){
        setDisplayModalPhoto(true);
        if(e.target.getAttribute("src")){
          setModalPhotoSrc(e.target.getAttribute("src"));
        }
      }

      const setStateModal = async (childrenData) => {
        if(childrenData){
          setDisplayModalPhoto(false);
        }
      }
      const setStateModalCredits = async (childrenData) => {
        if(childrenData){
          setDisplayModalTips(false);
        }
      }

      const setStateModalUnlockPhoto = async (childrenData) => {

        if(childrenData == false && modalIdPhotoUnlock){
          setDisplayModalUnlockPhoto(false);
        }

        if(childrenData && modalIdPhotoUnlock){
          axios
          .post(
            config.url + "order/picture/" + modalIdPhotoUnlock,
            {user_id: id,},
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((result) => {
            if (result.status === 200) {
              toast(t("You unlocked a photo"));
              getProfileDetails();
            }
          })
          .catch((error) => {
            toast(error.response.data.message);
            setTimeout(() => {
              navigate("/membership-credits");
          }, 3500);
          });

          setDisplayModalUnlockPhoto(false);
        }
      }


      useEffect(() => {
        getPricingPlans();
    }, []);

    const getPricingPlans = async () => {
        if (localStorage.getItem("token")) {
            const response = await axios.get(config.url + "products", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log(response.data);
            setPricingPlans(response.data);
        }
    };


  return (
    <section className="profile-section">
           <Breadcrumb pageTitle={user?.username + " " + t("Profile")} text = {t("Take a closer look at their profile and see if there's a spark waiting to ignite. You never know what exciting connections may lie ahead")}/>


      <div className="container">
      <div className="row">
          <div className="col-md-3 mb-3 left-side">
            <Account/>
        
          </div>
          <div className="col-md-9 mb-3 left-side">

          <div className="profile-header main-shadow-box bb-color">
            
               <div className="profile-header-details ">
                  <div className="photo-box">
                    <img src={user?.main_pic ? user.main_pic : loadingGif} className="profile-header-photo img-background" alt="profile-img"/>
                  </div>
                  <div className="profile-user-details ">
                    <span className="bld">{user?.username ? user.username : "" }</span>
                    <span><FaCircle className={user?.is_online && user.is_online == true ? 'online-circle' : 'offline-circle'} /> {user?.is_online && user.is_online == true ? 'online' : "offline" }</span>
                  </div>

              </div>


              <div className="profile-all-buttons">
                
              <div className="profile-member-buttons">
                <Link to={"/messages/" + id}><button className="main-btn tb-color f-white howver">{t("Send message")}</button></Link>
                {user?.isCompatibleMembership ?  <button className="main-btn pb-color t-color howver-white">  <a href={"javascript:jqcc.cometchat.launch({uid:'"+id+"'});"}>{t("Chat voice or video")}</a></button> :<button className="main-btn pb-color t-color" onClick={() => setWarningMessage(!warningMessage)}> {t("Chat voice or video")}</button>}
               

                </div>
                <div className="profile-member-buttons">
                <button className="main-btn tb-color f-white howver" onClick={() => setDisplayModalTips(true)}> <FaMoneyCheck/> {t("Send tips")}</button>
                {(user?.liked || userLiked) && 
                          <button className="main-btn pb-color t-color howver-white"><AiFillLike title="Already liked" className=""/> {t("Already liked")}</button>
                }
                {(!user?.liked && userLiked == undefined) && 
                    <button className="main-btn pb-color t-color howver-white"><AiFillLike title="Like" onClick={likeProfile} className=""/> {t("Like")}</button>
                }
                </div>



            </div>
            
            </div>

          
            <div className="user-details section-details main-shadow-box bb-color">
            {warningMessage && <div className="bb-color t-color alert  alert-danger">{t("This feature requires both you and your partner to have the same membership level.")}</div>}

            <h3>{t("Details")}</h3>
   

                <div className="item"> 
                    <div className="item-block ">
                        
                        <span>{t("Age")}</span>
                        <span className="item vb-color">  <FaBirthdayCake/>{user?.age ? user.age + ' years' : "" }</span>
                    </div>
             

                </div>
                <div className="item"> 
                    <div className="item-block ">
                        
                        <span>  {t("Gender")}</span>
                        <span className="item vb-color"> <FaVenusMars/> {user?.gender ? user.gender : "" }</span>
                    </div>
             

                </div>
                <div className="item"> 
                    <div className="item-block ">
                        
                        <span>  {t("Country")}</span>
                        <span className="item vb-color"> <BsGlobe/> {user?.country ? user.country : "" }</span>
                    </div>
             

                </div>
                <div className="item"> 
                    <div className="item-block ">
                        
                        <span>   {t("City")}</span>
                        <span className="item vb-color">  <FaRegBuilding/> {user?.city ? user.city : "" }</span>
                    </div>
             

                </div>
                <div className="item"> 
                    <div className="item-block ">
                        
                        <span> {t("Looking for")}</span>
                        <span className="item vb-color"> <AiOutlineSearch/> {user?.looking_for ? user.looking_for : "" }</span>
                    </div>
             

                </div>
                <div className="item"> 
                    <div className="item-block ">
                        
                        <span> {t("Civil status")}</span>
                        <span className="item vb-color"> <AiFillHeart/> {user?.civil_status ? user.civil_status : "" }</span>
                    </div>
             

                </div>

            </div>  
          { user?.pictures && user.pictures.length > 0 && 
            <div className="user-details member-img-all">
              
            <h3>{t("Photos")}</h3>
   
            {user?.pictures && user.pictures.length > 0 &&
                    user.pictures.map((photo) => (
                        <div className="member-details-img img-background">
                        {photo.blocked && <div className="unlock-photo" data-id={photo.id} data-credits={photo.price} onClick={setModalUnlock}><AiFillLock/> <span>{photo.price} credits</span></div>}
                        {/* <img id={"id" + photo.id} onClick={photo.blocked ? unblockPhoto : ""} class={photo.blocked ? 'photo-blocked' : ''} src={photo.src} alt="profile-member-img" /> */}
                        <img src={photo.src} class={photo.blocked ? 'photo-blocked' : ''}  alt="profile-member-img" onClick={handleImageClick}/>
                        </div>
                        ))
                    

            }
    

            </div>
            }
          {
            (gifts &&  <div className="user-details">
               <h3>{t("Make a gift")}</h3>
               {
                  gifts.map((gift, index) => {
                  return <div className="flex-start each-user make-gift" data-id={gift.id} data-title={gift.name} data-credits={gift.price} title={gift.name} onClick={handleGiftClick} style={{width:"25%", marginTop:"10px", cursor: "pointer", color:"var(--main-color)"}}>
                    <div className="featured-users-each-image">
                      <img className="gift-photo bb-color" data-id={gift.id} data-credits={gift.price} data-title={gift.name} title={gift.name} src={gift.picture}></img>
                    </div>
                    <div className="gift-name" style={{marginTop:"35px",marginLeft:"25px"}}><strong>{gift.name}</strong></div></div>
                  })}
            </div>)
          }
          {
            (pricingPlans?.memberships &&  <div className="user-details">
               <h3 className="mt-5">{t("Memberships")}</h3>
               <div className="row w-100">
               {
                  pricingPlans?.memberships.map((membership, index) => {
                  return <div className="col-sm flex-start each-user make-gift" style={{marginTop:"10px", color:"var(--main-color)"}}>
  
                    <div className="featured-users-each-image">
                      <img className="gift-photo bb-color" src={membership.picture}></img>
                    </div>
                    <Link
                                                    to={
                                                        "/checkout/memberships/" +
                                                        membership.id +
                                                        "?receiver_id=" +
                                                        id
                                                    }
                                                >     
                    <div className="link-navigation" style={{marginTop:"35px",marginLeft:"25px", color:"var(--main-color)"}}><strong>{membership.name}</strong></div>
                    
                    </Link>   
                    </div>
                    
                  })}
                  </div>
            </div>)
          }


{displayModalPhoto && modalPhotoSrc && (
        <ImageModal
          src={modalPhotoSrc}
          close={setStateModal}
        />
      )}

{displayModalTips && id && (
        <CreditsModal
          sendTo={id}
          sendToUsername={user.username}
          close={setStateModalCredits}
        />
      )}

{displayModalUnlockPhoto && modalUnlockPhotoValue && modalUnlockPhotoCredits && <Modal text={modalUnlockPhotoText} credits={modalUnlockPhotoCredits} confirm={setStateModalUnlockPhoto} />}
{displayModalGift && giftModalId && giftModalCredits && <Modal text={giftModalText} credits={giftModalCredits} confirm={setStateModalGift} />}
          </div>

      </div>
      <div className="left-side">

      </div>

      </div>
    </section>
  )
}

export default ProfileUser