import React from 'react';
import {useTranslation} from 'react-i18next';


function Breadcrumb(props) {
  const { t, i18n, ready } = useTranslation();
  const text = props?.text ? props.text : t("Be a hopeless romantic with a dirty mind!");
  return (
    <div className="general-breadcrumbs">
    <div className="container">
      {/* <h3>{props?.pageTitle}</h3>
      <p>{text}</p> */}
    </div>
  </div>
  )
}

export default Breadcrumb